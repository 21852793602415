.settings-back-button{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 1rem;
}

.gridBox{
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
}

.gridBox .flexbox{
    display:flex;
    flex-direction: column;
    align-items: center;
    white-space: break-spaces;
}

.gridBox svg{
    font-size:3rem
}

.externalize{
    position:absolute;
    right:1rem;
    top:1rem;
}