.imgDisplay{
    max-width:500px;
    max-height:500px
}

.imgDisplay img{
    width:100%;
    height:100%
}

.searchInputDefault{
    display: flex;
    justify-content: flex-end;
}

.headerRow{
    font-weight: bold;
    border-radius: .25rem;
}

.defaultNotFound {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    margin:1rem
}

.spinner {
    animation: spin-animation 1s infinite;
    animation-timing-function: linear;
    display: inline-block;
}

h2.default-dialog{
    display:flex;
    justify-content: space-between;
    font-weight: bold;
}


@keyframes spin-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}