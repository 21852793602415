.profile-overflow{
    overflow: auto;
    max-height: 78svh;
}

.adress-box.onProfile{
    background-color: white;
}
.profile-save-button{
    box-shadow:1px 1px 9px 1px #707070;
    height:3rem
}

.profile-save-button .flexbox{
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:.7rem
}

.item-content-modal{
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 1rem 0rem;
}