.school-header {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.school-name.config-width{
    width:100%;
    max-width: 30rem;
}

.school-name{
    display:flex;
    gap:1rem;
    align-items: center;
}

.teams-container{
    height: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

button.buttonstyle{
    height:3rem;
    padding:.5rem 2rem;
    box-shadow: 1px 1px 9px 1px #707070;
    border-radius: .35rem;
}

.school-adress{
    gap: 1rem;
    align-items: center;
    display: flex;
    
}

.school-adress .MuiFormControl-root{
    background-color: white;
    width: 30rem;
}

.school-container{
    flex:1;
    width:100%;
    min-width: 24rem;
    height:100%
}

.school-container-container {
    border-radius: .25rem;
    padding: 2rem 0rem 0rem 0rem;
    position: relative;
    height:100%;
    display:flex;
    flex-direction: column;
}

.school-container-container .school-container-label {
    position: absolute;
    top: 0px;
    background-color: #f7f7f7;
}

.menu-divider {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    height: 100%;
    overflow:auto
}

.default-column-spacing{
    display:flex;
    gap:1rem;
    flex-direction: column;
}


.default-row-spacebetween{
    display:flex;
    gap:1rem;
    justify-content: space-between;
    align-items: center;
}

.cupselectbutton{
    display:flex;
    justify-content: space-between;
    gap: 1rem
}
.cupselectbutton > button{
    flex: 1
}

.greenCell{
    background-color: green;
    width:100%;
    height:100%;
    cursor:pointer
}

.redCell{
    background-color: red;
    width:100%;
    height:100%;
    cursor:pointer
}

.table-divider{
    display:flex;
    flex-direction: column;
    overflow: auto;
}

.divider-half{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.round-flex{
    display:flex;
    gap:1rem;
    align-items: center;
    font-weight: bold;
}

.round-flex div{
    min-width: 12rem;
    max-width: 12rem;
    overflow:auto
}

.round-card{
    padding: 0.5rem;
    background-color: white;
    border-radius: .25rem; 
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.match-columns{
    display:flex;
    flex-direction: column;
    gap:.5rem
}
.match-row{
    display:flex;
    align-items: center;
    gap: 0.5rem
}

.match-row .match-item{
    min-width: 8rem;
    max-width: 8rem;
    overflow: auto
}

.match-row .half-match-item{
    min-width: 4rem;
    max-width: 4rem;
    overflow: auto
}

.match-row input{
    text-align: center;
    max-width: 1.1rem;
    max-height: 1rem;
    padding: 0.5rem;
}

.time-wrap{
    flex-wrap: wrap;
    justify-content: left;
}

.time-wrap Button{
    width: 4rem
}

.invisible-box{
    background-color:black
}


.limited-size-dialog-box{
    display: flex;
    overflow: auto;
    flex-direction: column;
    height: 30rem;
}

@media screen and (max-width: 1321px) {
    .MuiDataGrid-toolbarContainer button{
        font-size:.7rem
    }
}


@media screen and (max-width: 1190px) {
    .MuiDataGrid-toolbarContainer button{
        font-size:.6rem
    }
}

@media screen and (max-width: 1098px) {
    .MuiDataGrid-toolbarContainer button{
        font-size:.5rem
    }
}

@media screen and (max-width: 1003px) {
    .MuiDataGrid-toolbarContainer button{
        font-size:.4rem
    }
}