.school-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.school-name.config-width{
    width:100%;
    max-width: 30rem;
}

.school-name{
    display:flex;
    gap:1rem;
    align-items: center;
}

.school-name input{
    background-color: white;
}

button.buttonstyle{
    height:3rem;
    padding:.5rem 2rem;
    box-shadow: 1px 1px 9px 1px #707070;
    border-radius: .35rem;
}

.school-adress{
    gap: 1rem;
    align-items: center;
    display: flex;
    
}

.school-adress .MuiFormControl-root{
    background-color: white;
    width: 30rem;
}

.school-container{
    flex:1;
    width:100%;
    min-width: 24rem;
    height:100%
}

.school-container-container {
    border-radius: .25rem;
    padding: 2rem 0rem 0rem 0rem;
    position: relative;
    height:100%;
    display:flex;
    flex-direction: column;
}

.school-container-container .school-container-label {
    position: absolute;
    top: 0px;
    background-color: #f7f7f7;
}

.menu-divider {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    height: 100%;
    overflow:auto
}

.default-column-spacing{
    display:flex;
    gap:1rem;
    flex-direction: column;
}


.default-row-spacebetween{
    display:flex;
    gap:1rem;
    justify-content: space-between;
    align-items: center;
}